import React from 'react'
import { graphql, Link } from 'gatsby'
import get from 'lodash/get'
import Helmet from 'react-helmet'
import Img from 'gatsby-image'
import Layout from '../components/layout'
const axios = require('axios')
import header from '../images/CleanAir-Desktop-Header-1920x340-Curve-DataHub-2x.png'
import headerMobile from '../images/CleanAir-Mobile-Header-1080x550-Curve-DataHub-2x.png'
import { Container, Row, Col, Modal, ModalBody } from 'reactstrap'

class DataHubIndex extends React.Component {
  render() {
    const siteTitle = get(this, 'props.data.site.siteMetadata.title')
    const linkBoxes = get(this, 'props.data.contentfulDataHub.linkBoxes')
    const alerts = get(this, 'props.data.contentfulDataHub.alerts')
    const pageTitle = get(this, 'props.data.contentfulDataHub.name')
    const metaDescription = get(
      this,
      'props.data.contentfulDataHub.metaDescription'
    )
    const metaImageURL = get(
      this,
      'props.data.contentfulDataHub.metaImage.file.url'
    )

    return (
      <Layout location={this.props.location}>
        <div>
        <Helmet title={pageTitle + ' | ' + siteTitle}>
            <meta name="description" content={metaDescription} />
            <meta
              property="og:url"
              content={'https://cleanairgm.com/data-hub'}
            />
            <meta property="og:type" content={'website'} />
            <meta property="og:title" content={pageTitle + ' | ' + siteTitle} />
            <meta property="og:description" content={metaDescription} />
            <meta property="og:image" content={metaImageURL} />
          </Helmet>          
          <div className="page-banner page-banner--disclaimer">
            <img className="d-none d-lg-block" src={header} alt="" />
            <img className="d-block d-lg-none" src={headerMobile} alt="" />
            <h1>Data hub</h1>
          </div>
        </div>

        

        <div className="pb-5 pt-4">
          <Container className="text-center mt-lg-4 mb-4">
            <h2 className='h2-potential'>Data and reports</h2>
            <p>
              Find the latest air pollution information for Greater Manchester.
            </p>
          </Container>
          <Container className="vehicle-boxes">
            <Row>
              {linkBoxes.map((v, i) => (
                <Col key={`vb${i}`} xs={6} lg={3} className="mb-5 mb-lg-0 vb">
                  <div>
                    <Img className="br-10" fluid={v.background.fluid} />
                    <h3>{v.name}</h3>
                    <div
                      className="vehicle-boxes__text vehicle-boxes__text--long"
                      dangerouslySetInnerHTML={{
                        __html: v.text.childMarkdownRemark.html,
                      }}
                    ></div>
                  </div>
                  <Link className="btn btn-content" to={v.link}>
                    View
                  </Link>
                </Col>
              ))}
            </Row>
          </Container>
        </div>

        <div className="pt-4 pb-4 data-switch">
          <Container className="mt-4 mb-4 text-center text-lg-left">
            <Row>
              <Col
                xs={{ order: 1 }}
                sm={12}
                col
                lg={{ size: 6, order: 0 }}
                className="mt-5 mt-lg-0"
              >
                <h2>{alerts.title}</h2>
                <div
                  className="alerts__text"
                  dangerouslySetInnerHTML={{
                    __html: alerts.text.childMarkdownRemark.html,
                  }}
                ></div>
                <p>
                  <Link
                    className="btn btn-call"
                    to={'/data-hub/forecast-and-alerts/text-sign-up'}
                  >
                    Text
                  </Link>
                  <Link
                    className="btn btn-call"
                    to={'/data-hub/forecast-and-alerts/email-sign-up'}
                  >
                    Email
                  </Link>
                  <Link
                    className="btn btn-call"
                    to={'/data-hub/forecast-and-alerts/recorded-call-sign-up'}
                  >
                    Recorded call
                  </Link>
                </p>
              </Col>
              <Col sm={12} col lg={{ size: 6 }}>
                <Img className="alerts__image br-10" fluid={alerts.image.fluid} />
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    )
  }
}

export default DataHubIndex

export const pageQuery = graphql`
  query DataHubQuery {
    site {
      siteMetadata {
        title
      }
    }
    contentfulDataHub {
      id
      name
      metaDescription
      metaImage {
        file {
          url
        }
      }
      linkBoxes {
        name
        text {
          childMarkdownRemark {
            html
          }
        }
        background {
          fluid(maxWidth: 325, maxHeight: 200) {
            ...GatsbyContentfulFluid
          }
        }
        link
      }
      alerts {
        text {
          childMarkdownRemark {
            html
          }
        }
        id
        title
        image {
          fluid(maxWidth: 670, maxHeight: 377) {
            ...GatsbyContentfulFluid
          }
        }
      }
    }
  }
`
